//Main
.mainSleder
    &.home-header__content
        height: 100vh

    .slider-container
        width: 100%
        height: 100%
        overflow: hidden
        position: relative

    .slider-bottom
        position: absolute
        bottom: 0
        display: flex
        align-items: center
        z-index: 5
        left: var(--size)
        height: var(--size)
        width: calc(75% - var(--size))

    .slider-share
        flex: 0 1 auto

    .slider-scrollbar
        flex: 1 0 0

        .swiper-scrollbar
            height: 1px
            background-color: rgba(255, 255, 255, 0.2)

        .swiper-scrollbar-drag
            height: 3px
            background-color: var(--colorBlue)
            top: -1px

    .slider-navigation__container
        max-width: 70px
        position: relative
        margin: auto

    .slider-navigation
        flex: 0 0 140px
        position: relative
        left: 10px

        .swiper-button-prev, .swiper-button-next
            width: 32px
            height: 32px
            color: var(--colorBlue)
            background-color: #fff
            border-radius: 100%
            border: 2px solid var(--colorBlue)
            top: 5px

        .swiper-button-prev::after, .swiper-button-next::after
            font-size: 10px

        .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled
            opacity: 1
            color: var(--colorBlue)
            border-color: #ffff

        .swiper-button-prev
            left: 0

        .swiper-button-next
            right: 0

    .slider-share__items
        display: flex
        position: relative
        bottom: -.5px

    .slider-share__item
        width: var(--size)
        height: var(--size)
        border: none
        background-color: var(--dark)
        border-right: 1px solid rgba(255, 255, 255, var(--border-opacity))
        margin-left: -.5px

        &:last-child
            border: none

            &::after
                content: ''
                position: absolute
                top: 0
                right: 0
                width: 3px
                height: 100%
                background-color: var(--colorBlue)

    .slider-img
        height: 100%
        width: 100%
        overflow: hidden
        position: relative

        //&::before
            content: ''
            position: absolute
            width: 100%
            height: 100%
            background-color: rgba(0, 0, 0, 0.45)
            z-index: 2

    .slider-img__slide
        height: 100%
        overflow: hidden

    .slider-img__bg
        height: 100%
        background-position: center
        background-size: cover

    .slider-text
        position: absolute
        z-index: 3
        width: 100%
        height: calc(100% - var(--size))

    .slider-text__slide
        display: flex
        justify-content: center
        flex-direction: column

    .slider-text__content
        text-align: right
        padding-right: 22%

    .slider-text__heading
        font-weight: 700
        text-transform: uppercase
        font-size: 60px
        line-height: 120%
        font-family: var(--userfont)
        max-width: 780px
        float: right
        color: #ffffff
        text-shadow: 3px 3px 2px rgba(21, 96, 189,0.27)
        span
            color: var(--colorBlue)
            white-space: nowrap
            text-shadow: 3px 3px 2px rgba(255, 255, 255, 0.49)

    .slider-text__desc
        float: right
        max-width: 500px
        font-size: 19px
        font-weight: 500
        margin-top: 15px
        margin-bottom: 20px
        clear: both
        color: #ffffff
        text-shadow: 2px 2px 2px rgba(21, 96, 189, 0.67)

    .slider-text__button
        float: right
        clear: both

    .slider-pagination
        flex: 0 0 140px
        display: flex
        justify-content: center
        height: var(--size)
        align-items: center
        position: relative

        .swiperPagination
            margin-top: 17px
            position: relative

        .swiper-pagination-bullet
            width: 6px
            height: 6px
            opacity: 1
            background-color: #fff
            margin: 0 7.5px

        .swiper-pagination-bullet-active
            background-color: var(--colorBlue)
            position: relative

            &::before
                content: ''
                width: 14px
                height: 14px
                border: 1px solid var(--colorBlue)
                position: absolute
                border-radius: 100%
                left: -4px
                top: -4px
    .swiper-scrollbar
        border-radius: var(--swiper-scrollbar-border-radius, 10px)
        position: relative !important
        -ms-touch-action: none
        background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1))


//parthers
.caruselPartners
    position: relative
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal
        bottom: var(--swiper-pagination-bottom, 3px)
    .swiper-slide
        height: auto
        img
            filter: grayscale(100%)
            -webkit-transition: all .4s ease-in-out
            -moz-transition: all .4s ease-in-out
            -ms-transition: all .4s ease-in-out
            -o-transition: all .4s ease-in-out
            transition: all .4s ease-in-out
            &:hover
                filter: grayscale(0)

.mainSlide
    position: relative
    .swiper-button-next, .swiper-rtl .swiper-button-prev
        right: var(--swiper-navigation-sides-offset, -40px)
    .swiper-button-prev, .swiper-rtl .swiper-button-next
        left: var(--swiper-navigation-sides-offset, -40px)
.mainSlide,
.mainProjects,
.caruselPartners,
.swiper-reviews,
.main-news
    .swiper
        padding-top: 32px
        padding-bottom: 48px
.mainProjects,
.swiper-reviews,
.main-news
    position: relative
    .swiper-button-next,
    .swiper-button-prev
        &::after
            content: " "
            width: 100%
            height: 100%
            display: block
            font-family: inherit
            font-size: 20px
            background-size: 1em 1em
            background-position: center center
            background-repeat: no-repeat
            background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20width%3D%2219px%22%20height%3D%2218px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20%20fill%3D%22%23fff%22%20d%3D%22M19.006%2C9.036%20L9.615%2C18.005%20L7.958%2C16.421%20L14.551%2C10.124%20L-0.001%2C10.124%20L-0.001%2C7.874%20L14.553%2C7.874%20L7.958%2C1.574%20L9.615%2C-0.008%20L19.006%2C8.962%20L18.967%2C8.999%20L19.006%2C9.036%20Z%22/%3E%3C/svg%3E")
    .swiper-button-prev
        &::after
            transform: rotate(180deg)
    .swiper-button-prev,
    .swiper-button-next
        width: 50px
        height: 50px
        background: #16233c
        border-radius: 50%
        top: 30px
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled
        pointer-events: visible
    .swiper-button-next, .swiper-rtl .swiper-button-prev
        right: var(--swiper-navigation-sides-offset, 0)
    .swiper-button-prev, .swiper-rtl .swiper-button-next
        right: var(--swiper-navigation-sides-offset, 85px)
        left: auto
/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)
    .review-list
        .review
            padding: 15px 15px 20px

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)

// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .mainSleder
        .slider-text__heading
            font-size: 50px
            max-width: 500px
        .slider-navigation
            display: none
        .slider-text__content
            padding-right: 10%
    .review-list
        .name,
        .font-body
            font-size: 15px
        .subtitle
            font-size: 13px

// Малые девайсы («ландшафтные», >= 575px)
@media (min-width: 480px) and (max-width: 767.98px)
    .mainSleder
        .slider-text__content
            padding-right: 27%
            padding-left: 22%
            padding-top: 10%
            text-align: center
            .slider-text__heading
                font-size: 35px
            .slider-text__desc
                font-size: 15px

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 575.98px)
    .mainSleder
        .slider-navigation
            left: -20px

        .slider-share__item
            width: var(--size-sm)
            height: var(--size-sm)

        .slider-img
            width: 100%

        .slider-text
            height: calc(100% - var(--size-sm))

        .slider-text__content
            padding-left: 20px

        .slider-text__heading
            font-size: 28px

        .slider-text__desc
            font-size: 12px
        .slider-bottom
            width: calc(93% - var(--size-sm))
            height: var(--size-sm)
            left: var(--size-sm)
    .review-list
        .name,
        .font-body
            font-size: 14px
        .subtitle
            font-size: 12px

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)


/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)

// ≤1200px up ↑ min xl
@media (min-width: 1200px)
    .text
        font-size: 18px
// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)
    .text
        font-size: 22px
// ≤1565px up ↑
@media (min-width: 1565px)