.list-detail
    &> li
        margin-bottom: 45px
        &:last-child
            margin-bottom: 0
.list-marked
    &> li
        padding-left: 35px
        position: relative
        &::before
            width: 8px
            height: 8px
            position: absolute
            left: 10px
            top: 2px
            content: ''
            transform: rotate(-42deg)
            transform-origin: right
            margin-top: 4px
            border-radius: 0px
            border-right: 2px solid #aeb1be
            border-bottom: 2px solid #aeb1be
.box-feedback-form
    padding: 15px 15px
    .alert-success
        color: #bee260
    .dropzone
        border: 2px dashed #3498db !important
        border-radius: 5px
        transition: .2s
        &.dz-clickable
            span
                color: #1560bd
    .form-control
        padding: 0.775rem 0.75rem
        margin-bottom: 15px
        background: #f5f5f7
        &:focus
            box-shadow: inherit
    .button
        color: #000
        font-size: 14px
        font-weight: 600
        text-transform: uppercase
        //background-color: var(--accent)
        padding: 14px 35px
        //border: 0
        margin-top: 35px
        cursor: pointer

    .input-file
        position: relative
        display: inline-block
        margin-bottom: 15px

    .input-file-text
        width: 243px
        height: 40px
        padding: 0 10px
        line-height: 40px
        text-align: left
        display: block
        float: left
        box-sizing: border-box
        border-radius: 2px 0 0 2px
        border: 1px solid #8f8f9d

    .input-file-btn
        position: relative
        display: inline-block
        cursor: pointer
        outline: none
        text-decoration: none
        font-size: 14px
        vertical-align: middle
        color: rgb(255 255 255)
        text-align: center
        border-radius: 0 2px 2px 0
        background-color: var(--backgroundBlue)
        line-height: 22px
        height: 40px
        padding: 10px 20px
        box-sizing: border-box
        border: none
        margin: 0
        transition: background-color 0.2s

    .input-file
        input[type=file]
            position: absolute
            z-index: -1
            opacity: 0
            display: block
            width: 0
            height: 0

        //&:focus + .input-file-btn
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)

        &:hover .input-file-btn
            background-color: rgba(40, 64, 182, 0.9)

        &:active .input-file-btn
            background-color: var(--backgroundBlue)

        input[type=file]:disabled + .input-file-btn
            background-color: #eee


/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .box-feedback-form
        .successForm
            display: none
        .dropzoneForm.hide
            display: none
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .box-feedback-form
        .input-file-text
            width: 208px
// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)
    .box-feedback-form
        .input-file-text
            width: 292px
// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)
    .box-feedback-form
        .input-file-text
            width: 166px
// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .box-feedback-form
        .successForm
            position: relative
            z-index: -1
            opacity: 0
        .dropzoneForm.hide
            position: relative
            z-index: -1
            opacity: 0
// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
