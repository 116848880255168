.b-wrap
    min-height: 100%
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
    -ms-flex-direction: column
    flex-direction: column
    overflow: hidden
    background-color: #fff
.section-bredcrumbs
    padding-top: 100px
    padding-bottom: 31px
    position: relative
    background: url("../images/bg-5.jpg") center
    background-size: cover
    .breadcrumb
        margin-top: 40px
        li
            font-size: 14px
            color: #ffffff
            a
                color: #ffffff
                text-decoration: none
                &:hover
                    text-decoration: underline
    .h2
        font-size: 32px
        color: #ffffff
.home-header
    width: 100%
    position: absolute
    z-index: 5
.logoTop
    width: 70%
    display: block
.logoFooter
    width: 40%
    display: block
    margin-bottom: 13px
.b-content
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1
    position: relative
.section-lg
    padding: 50px 0
.iconSvgPhone
    width: 25px
    height: 25px
    margin-right: 15px
    fill: #ffffff
.iconSvgHeartConvert
    width: 25px
    height: 25px
    margin-right: 15px
    fill: #ffffff
.iconSvgHeartMaps
    width: 31px
    height: 31px
    margin-right: 15px
    fill: #ffffff
.title-sm
    margin-bottom: 30px
    position: relative
    z-index: 1
    .h2
        font-weight: 700
        //color: var(--colorBlue)
        text-align: center
        text-transform: uppercase
        position: relative
        &:before
            content: ""
            width: calc(35% - 25px)
            height: 3px
            background: var(--accent)
            position: absolute
            z-index: 0
            left: 0
        &:after
            content: ""
            width: calc(35% - 25px)
            position: absolute
            height: 3px
            background: var(--accent)
            right: 0

.sectionBlockTwo
    position: relative
    .text-block-1,
    .text-block-2
        position: relative
        z-index: 1
.sectionBlockFour
    .sectionTextPreview
        position: relative
        z-index: 1
.sectionBlockFive
    //background: linear-gradient(45deg, rgba(47,199,247,1) 0%, rgba(64,158,239,1) 100%)
    background: var(--backgroundBlue)
    .title-sm
        .h2
            color: #ffffff
            &:before
              background-color: #FFFFFF
            &:after
                background-color: #FFFFFF
.sectionBlockSix
    background-color: var(--bgGraywhite)

.icon-phone
    width: 20px
    height: 20px
    margin-right: 5px
    fill: #fff
.icon-mail
    width: 20px
    height: 20px
    margin-right: 5px
    fill: #fff
.icon-start
    width: 50px
    height: 50px
    position: relative
    z-index: 2
    fill: none
    stroke-width: 8px
    stroke: #FFFFFF
.icon-Laptop_hone
    width: 50px
    height: 50px
    position: relative
    z-index: 2
    fill: none
    stroke-width: 5px
    stroke: #FFFFFF
.icon-technical_support
    width: 50px
    height: 50px
    position: relative
    z-index: 2
    fill: none
    stroke-width: 5px
    stroke: #FFFFFF
.icon-all
    width: 70px
    height: 80px
    position: relative
    z-index: 2
    fill: none
    stroke-width: 2px
    stroke: var(--backgroundBlue)

.scrollToTopBtn
    width: 60px
    height: 60px
    display: none
    position: fixed
    bottom: 20px
    right: 20px
    font-size: 24px
    border-radius: 50%
    border: none
    background-color: var(--accent)
    color: #fff
    cursor: pointer
    z-index: 5
    &:after
        position: absolute
        top: 21px
        right: 36px
        transform: rotate(-134deg)
        transform-origin: right
        width: 15px
        height: 15px
        border-radius: 0px
        border-right: 2px solid #fff
        border-bottom: 2px solid #fff
        content: ""


/*==========  Desktop First  ==========*/

@media (max-width: 767.98px)
    .breadcrumb
        display: none
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    html, body
        font-size: 0.775rem
        p
            font-size: 0.999rem
            line-height: 1.35
            margin-bottom: 0.545rem
    .logoTop
        width: 20%
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    html, body
        font-size: 0.975rem
        p
            font-size: 0.899rem
            line-height: 1.35
            margin-bottom: 0.545rem
    .scrollToTopBtn
        display: none !important
    .logoTop
        width: 20%
    .title-sm
        margin-bottom: 15px
        .h2
            &::before
                width: calc(25% - 25px)
            &::after
                width: calc(25% - 25px)
// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 575.98px)
    html, body
        font-size: 0.975rem
        p
            font-size: 0.899rem
            line-height: 1.35
            margin-bottom: 0.545rem
    .logoTop
        width: 28%
// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)
    html, body
        font-size: 0.875rem
    .logoTop
        width: 40%
// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)
    .section-lg
        padding: 65px 0
    .title-sm
        .h2
            font-size: 35px

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .section-lg
        padding: 65px 0
    .section-bredcrumbs
        padding-top: 125px
        .breadcrumb
            margin-top: 112px
        .h2
            font-size: 70px
// ≤1200px up ↑ min xl
@media (min-width: 1200px)
    .section-bredcrumbs
        padding-top: 158px
    .section-lg
        padding: 100px 0

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
    .section-bredcrumbs
        padding-top: 216px
    .section-lg
        padding: 123px 0
