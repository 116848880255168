.pagination
    .prev,
    .next
        height: 100%
        text-indent: -9999px
        font-size: 0
        padding-left: 20px !important
        padding-right: 20px !important
        &::after
            width: 11px
            height: 11px
            content: ""
            position: absolute
            border-radius: 0px
            border-right: 2px solid var(--colorBlue)
            border-bottom: 2px solid var(--colorBlue)
    .prev
        &::after
            top: 18px
            left: 7px
            transform: rotate(136deg)
            transform-origin: right
    .next
        &::after
            top: 9px
            right: 19px
            transform: rotate(-44deg)
            transform-origin: right

    .page-link
        position: relative
        display: block
        color: #0d6efd
        text-decoration: none
        background-color: #fff
        border: 1px solid #dee2e6
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
        &:hover
            z-index: 2
            color: #0a58ca
            background-color: #e9ecef
            border-color: #dee2e6
        &:focus
          box-shadow: inherit
    &.active,
    .active
        &> .page-link
            z-index: 3
            color: var(--bs-pagination-active-color)
            background-color: var(--bs-pagination-active-bg)
            border-color: var(--bs-pagination-active-border-color)
    &.disabled,
    .disabled > .page-link
        color: var(--bs-pagination-disabled-color)
        pointer-events: none
        background-color: var(--bs-pagination-disabled-bg)
        //border-color: var(--bs-pagination-disabled-border-color)
    &.disabled,
    .disabled
        &>  .prev,
            .next
                &::after
                    border-right: 2px solid #dee2e6
                    border-bottom: 2px solid #dee2e6

