.hamburger
    display: inline-block
    cursor: pointer
    -webkit-transition-property: opacity, -webkit-filter
    transition-property: opacity, -webkit-filter
    -o-transition-property: opacity, filter
    transition-property: opacity, filter
    transition-property: opacity, filter, -webkit-filter
    -webkit-transition-duration: 0.15s
    -o-transition-duration: 0.15s
    transition-duration: 0.15s
    -webkit-transition-timing-function: linear
    -o-transition-timing-function: linear
    transition-timing-function: linear
    font: inherit
    color: inherit
    text-transform: none
    background-color: transparent
    border: 0
    margin: 0
    overflow: visible
    z-index: 5

    &:hover
        opacity: 0.7

.hamburger-box
    width: 32px
    height: 24px
    display: inline-block
    position: relative

.hamburger-inner
    display: block
    top: 50%
    margin-top: -2px
    width: 30px
    height: 4px
    background-color: #fff
    -webkit-border-radius: 4px
    border-radius: 4px
    position: absolute
    -webkit-transition-property: -webkit-transform
    transition-property: -webkit-transform
    -o-transition-property: transform
    transition-property: transform
    transition-property: transform, -webkit-transform
    -webkit-transition-duration: 0.15s
    -o-transition-duration: 0.15s
    transition-duration: 0.15s
    -webkit-transition-timing-function: ease
    -o-transition-timing-function: ease
    transition-timing-function: ease

    &::before, &::after
        width: 30px
        height: 4px
        background-color: #fff
        -webkit-border-radius: 4px
        border-radius: 4px
        position: absolute
        -webkit-transition-property: -webkit-transform
        transition-property: -webkit-transform
        -o-transition-property: transform
        transition-property: transform
        transition-property: transform, -webkit-transform
        -webkit-transition-duration: 0.15s
        -o-transition-duration: 0.15s
        transition-duration: 0.15s
        -webkit-transition-timing-function: ease
        -o-transition-timing-function: ease
        transition-timing-function: ease

    &::before, &::after
        content: ""
        display: block

    &::before
        top: -10px

    &::after
        bottom: -10px

.hamburger--spin
    .hamburger-inner
        -webkit-transition-duration: 0.3s
        -o-transition-duration: 0.3s
        transition-duration: 0.3s
        -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
        -o-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)

        &::before
            -webkit-transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in
            -o-transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in
            transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in

        &::after
            -webkit-transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)
            transition: bottom 0.1s 0.34s ease-in, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)
            -o-transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)
            transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)
            transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)

    &.is-active .hamburger-inner
        -webkit-transform: rotate(225deg)
        -ms-transform: rotate(225deg)
        transform: rotate(225deg)
        -webkit-transition-delay: 0.14s
        -o-transition-delay: 0.14s
        transition-delay: 0.14s
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
        -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)

        &::before
            top: 0
            opacity: 0
            -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out
            -o-transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out
            transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out

        &::after
            bottom: 0
            -webkit-transform: rotate(-90deg)
            -ms-transform: rotate(-90deg)
            transform: rotate(-90deg)
            -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
            transition: bottom 0.1s ease-out, -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
            -o-transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
            transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
            transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1)
