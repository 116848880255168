.b-wrap.page-news
    background-color: var(--bgGraywhite)
.catalog-news
    .bx-ui-tile
        height: 100%
        display: flex
        position: relative
        font-size: 1.25rem
        padding: 0 15px 30px
        box-sizing: border-box
        &.bx-ui-tile_btb
            padding: 0
            border-right: 1px solid var(--bgGraywhite)

    .bx-ui-tile__inner
        width: 100%
        display: block
        position: relative
        min-height: 300px
        background-color: #fff
        &:hover
            .product-showcase__media-picture
                transform: scale(1.1)
    .bx-ui-tile__main-link
        width: 100%
        height: 100%
        position: absolute
        z-index: 5
        top: 0
        left: 0
        display: block
        overflow: hidden
        transition: box-shadow 250ms ease,transform 250ms ease
        text-decoration: none
        cursor: pointer
        &:hover
            box-shadow: 0 19px 40px rgba(0,0,0,.11)
            &::after
                height: 80px
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMDAgMjUiPjxnPjxnPjxwYXRoIGZpbGw9IiMyODQwYjYiIGQ9Ik0wIDExaDkzLjg3OWwtOC40NTEtOC40NUw4Ny41NDkuNDI2bDEyLjAyIDEyLjAyMS0uMDUuMDUuMDUuMDVMODcuNTUgMjQuNTdsLTIuMTIxLTIuMTJMOTMuODc1IDE0SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)
                animation: fadeInUp 250ms ease-out
        &::before
            width: 100%
            height: 100px
            content: ""
            position: absolute
            bottom: 0
            left: 0
            padding: 0 40px
            transition: background-image 250ms ease
        &::after
            width: 100%
            height: 0
            content: ""
            position: absolute
            bottom: 0
            left: 0
            margin: 0 40px
            background-repeat: no-repeat
            background-position: left center
    .tile__img-box
        position: relative
        height: 0
        display: block
        padding-top: 40%
        overflow: hidden
        transition: padding-top 250ms ease
    .tile__img-box-inner
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        display: flex
    .product-showcase__media-picture
        width: 100%
        height: 100%
        margin: auto
        display: block
        max-width: 280px
        transition: 160ms linear all
    .bx-ui-tile__text-box
        min-height: 300px
        padding: 2em
    .bx-ui-tile__title
        font-size: 1em
        font-weight: 700
        line-height: 1.2em
        display: block
        margin-bottom: 1.25em


/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)

// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .catalog-news
        .bx-ui-tile__main-link
            box-shadow: 0 19px 40px rgba(0,0,0,.11)
            &::after
                height: 80px
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMDAgMjUiPjxnPjxnPjxwYXRoIGZpbGw9IiMyODQwYjYiIGQ9Ik0wIDExaDkzLjg3OWwtOC40NTEtOC40NUw4Ny41NDkuNDI2bDEyLjAyIDEyLjAyMS0uMDUuMDUuMDUuMDVMODcuNTUgMjQuNTdsLTIuMTIxLTIuMTJMOTMuODc1IDE0SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)
                animation: fadeInUp 250ms ease-out

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)

// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
