.b-wrap.page-vacancies
    //background-color: var(--bgGraywhite)
    .custom-bg-image
        background-repeat: no-repeat
        background-size: contain
        background-position: center bottom
.page-vacancies
    .call
        font-size: 21px
    .box-minimal
        height: 100%
        position: relative
        display: block
        padding: 30px 20px
        font-size: 18px
        line-height: 1.3
        background: #ffffff
        color: #2840b6
        border-radius: 6px
    .bg-gray-100
        background-color: #f5f5f7

    .title-sm
        .h2
            span
                padding-left: 25px
                padding-right: 25px
                position: relative
                z-index: 1
                background-color: #ffffff
                //background: var(--bgGraywhite)
    .bg-cover
        .title-sm
            .h2
                span
                    background: #f5f5f7


/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .b-wrap
        &.page-vacancies
            .custom-bg-image
                background-size: auto 40%
                padding-bottom: 135px
        .title-sm
            .h2
                span
                    width: 68%
                    padding-left: 0
                    padding-right: 0


// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)
    .page-vacancies
        .box-minimal
            padding: 63px 15px 63px 40px
            font-size: 25px
        .section-lg
            padding-bottom: 197px

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .page-vacancies
        .box-minimal
            padding: 63px 15px 63px 40px
            font-size: 25px

// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
    .page-vacancies
        .section-lg
            padding: 123px 0
        .section-bg-custom
            padding-bottom: 30%
        h2, .heading-2
            font-size: 48px
            line-height: 1.16667
        .h2 + h6,
        h2 + .heading-6
            margin-top: 56px
        .section-md
            padding: 100px 0 110px


