//$accent: orange // Accent Color
//$fontsize: 0.875rem // Body Font Size
//$textcolor: #333 // Text Color
//$lineheight: 1.65 // Document Line Height
//$userfont: roboto-oh5, sans-serif // User Font Family
//$systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif // System Font Family


\:root
    //--accent: #1560bd
    //--backgroundBlue: #1560bd
    //--colorBlue: #1560bd
    //--borderBlue: #1560bd

    //--accent: #1f90ef
    //--backgroundBlue: #1f90ef
    //--colorBlue: #1f90ef
    //--borderBlue: #1f90ef

    --accent: #202C75
    --backgroundBlue: #202C75
    --bgGraywhite: #f5f5f7
    --colorBlue: #202C75
    --colorGrey: #6f6f6f
    --colorGraywhite: #aeb1be
    --textcolor: #333
    --borderBlue: #202C75
    --userfont: oswald-vh, sans-serif
    --systemfont: roboto, -apple-system, BlinkMacSystemFont, Arial, sans-serif
    --size: 75px
    --fontsize: 0.875rem
    --size-sm: 58px
    --lineheight: 1.65

@mixin font($fname, $fstyle, $fweight, $furl)
    @font-face
        font-family: $fname
        font-style: $fstyle
        font-weight: $fweight
        src: url($furl) format('woff2')

@include font('oswald-vh', normal, 700, '../fonts/oswaldbold-webfont.woff2')
@include font('oswald-vh', normal, 500, '../fonts/oswaldmedium-webfont.woff2')
@include font('roboto', normal, 400, '../fonts/Roboto-Regular.woff2')
@include font('roboto', italic, 400, '../fonts/Roboto-Light.woff2')
@include font('roboto', normal, 700, '../fonts/Roboto-Bold.woff2')
@include font('roboto', italic, 700, '../fonts/Roboto-Medium.woff2')



::placeholder
    color: #666

::selection
    background-color: var(--accent)
    color: #fff

//input, textarea
    outline: none
    &:focus:required:invalid
        border-color: red
    &:required:valid
        border-color: green
html,
body
    min-height: 100%
    margin: 0 0

body
    font-family: var(--systemfont)
    font-size: var(--fontsize)
    line-height: var(--lineheight)
    color: var(--textcolor)
    min-width: 320px
    //padding-top: 85px
    position: relative
    overflow-x: hidden
    background: #ffffff
.bgWhite
    background: #FFFFFF
.button
    color: var(--borderBlue)
    cursor: pointer
    display: block
    position: relative
    z-index: 2
    border: 2px solid var(--borderBlue)
    background-color: #FFFFFF
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)
    &:link, &:visited
        position: relative
        display: block
        margin: 30px 0
        padding: 14px 15px
        color: #000
        font-size: 14px
        font-weight: bold
        text-align: center
        text-decoration: none
        text-transform: uppercase
        overflow: hidden
        letter-spacing: .08em
        border-radius: 0
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2)
        -webkit-transition: all 1s ease
        -moz-transition: all 1s ease
        -o-transition: all 1s ease
        transition: all 1s ease

    &:link:after, &:visited:after
        content: ""
        position: absolute
        height: 0%
        left: 50%
        top: 50%
        width: 150%
        z-index: -1
        -webkit-transition: all 0.75s ease 0s
        -moz-transition: all 0.75s ease 0s
        -o-transition: all 0.75s ease 0s
        transition: all 0.75s ease 0s

    &:link:hover, &:visited:hover
        color: #FFF
        text-shadow: none

    &:link:hover:after, &:visited:hover:after
        height: 450%

    &:link, &:visited
        position: relative
        display: block
        //margin: 0 auto
        padding: 14px 15px
        color: #000
        font-size: 14px
        border-radius: 0
        font-weight: bold
        text-align: center
        text-decoration: none
        text-transform: uppercase
        overflow: hidden
        letter-spacing: .08em
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2)
        -webkit-transition: all 1s ease
        -moz-transition: all 1s ease
        -o-transition: all 1s ease
        transition: all 1s ease

    &::before
        width: 0
        height: 100%
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        color: #000 !important
        background-color: var(--accent)
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)
    &.reverse
        &::before
            color: #fff !important
            background-color: #202C75
    &:hover
        cursor: pointer
        color: #ffffff !important
        text-shadow: nthree
        //background-color: var(--backgroundBlue)
        &::before
            left: 0%
            right: auto
            width: 100%
    //&.reverse
        &:hover
            color: #000 !important
    span
        position: relative
        z-index: 2
.pt-6
    padding-top: 7rem !important
.pb-6
    padding-bottom: 7rem !important
.mb-6
    margin-bottom: 60px
