.b-wrap.page-about
    background-color: var(--bgGraywhite)
.page-about
    .bg-default
        background-color: #ffffff
    * + .box-counter__divider
        margin-top: 10px

    .card-creative
        padding: 18px
        max-width: 350px
        margin-left: auto
        margin-right: auto
        background: #eff1f7
        text-align: center
        transition: .4s
        background: #ffffff
        &:hover
            box-shadow: 0px 1px 18px 0px rgba(1, 3, 4, 0.09)
            .card-creative__inner
                transform: translate3d(0, 0, 0)
            .thumbnail-inner
                opacity: 1
                visibility: visible
            .card-creative__media
                &::before
                    opacity: 1
                    visibility: visible
        .card-creative__inner
            position: relative
        .card-creative__title
            font-size: 20px
            letter-spacing: -0.025em
            line-height: 1.3
            color: #3a7bd5
        .card-creative__media
            max-width: 230px
            margin-left: auto
            margin-right: auto
            position: relative
            &::before
                content: ''
                opacity: 0
                visibility: hidden
                transition: .33s all ease-out
                position: absolute
                top: 0
                right: 0
                bottom: 0
                left: 0
                z-index: 1
                background: rgba(0, 0, 0, 0.5)
                pointer-events: none
        .thumbnail-inner
            color: #ffffff
            z-index: 2
            padding: 15px 10px
            margin-left: 20px
            margin-right: 20px
            .link-white
                transition: .33s all ease-out
                text-decoration: none
                color: var(--colorGraywhite)
                &:hover
                    color: #fff
        .card-creative__subtitle
            //font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif
            font-size: 16px
            letter-spacing: -0.025em
            line-height: 1.25
        .card-creative__divider
            font-size: 0
            line-height: 0
            &::before
                content: ''
                display: inline-block
                width: 135px
                height: 1px
                background: #e5e5e5

    .custom-bg-image
        background-repeat: no-repeat
        background-size: cover
        background-position: center bottom
    .parallax-container
        position: relative
        overflow: hidden
        background-attachment: fixed
        background-position: center center
        background-size: cover
    .parallax-content
        position: relative
        z-index: 1
        color: #ffffff
    .box-counter
        position: relative
        text-align: center
        color: #ffffff
    .box-counter__main > *
        display: inline
        font-size: 45px
        font-weight: 400
        line-height: 1.2
    .box-counter__divider::after
        content: ''
        display: inline-block
        width: 80px
        height: 2px
        background: #3a7bd5
    .box-counter__title
        display: inline-block
        letter-spacing: .02em
        line-height: 1.3
        font-size: 16px

    .text-style-1
        font-size: 16px
        line-height: 1.38889
        letter-spacing: .02em
    .tabs-custom
        text-align: left
    .tabs-corporate
        .nav-link
            padding: 10px 10px
            text-transform: uppercase
            font-size: 12px
            font-weight: 700
            line-height: 1.4
            color: #9b9b9b
            background: transparent
            border-bottom: 1px solid #e5e7e9
            text-align: center
            vertical-align: middle
            &:first-child
                border-top: 1px solid #e5e7e9
            &:hover,
            &.active
                color: #ffffff
                background: #3a7bd5
                border-color: #3a7bd5
    .tabs-horizontal.tabs-corporate.tabs-corporate_left .tab-content
        padding: 15px 0 0


/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .page-about
        .link-group
            .icon-mail,
            .icon-phone
                fill: var(--colorBlue)
        .custom-bg-image
            background-size: auto 60%
            padding-bottom: 100px
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .page-about
        .tabs-custom
            .nav
                display: block

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)
    .page-about
        .tabs-horizontal.tabs-corporate.tabs-corporate_left .tab-content
            padding: 30px 0 0

        .text-style-1
            font-size: 18px
        .tabs-horizontal.tabs-corporate.tabs-corporate_left .nav-tabs
            text-align: left
        .tabs-horizontal.tabs-corporate
            .nav-tabs
                position: relative
                width: 100%
                display: block
                justify-content: center
                text-align: center
                margin-bottom: -10px
                margin-left: -5px
                margin-right: -5px
                border: 0
                will-change: transform
                & > *
                    padding-right: 5px
                    margin-bottom: 10px
                    padding-left: 5px
            .nav-item
                display: inline-block
                will-change: transform
                flex-grow: 1
                flex-shrink: 0
            .nav-link
                display: block
                position: relative
                z-index: 1
                min-width: 130px
                letter-spacing: .1em
                padding: 13px 20px
                border: 2px solid #e5e7e9
                &::before
                    content: ''
                    position: absolute
                    top: -2px
                    left: -2px
                    width: calc(100% + 4px)
                    height: calc(100% + 4px)
                    z-index: -1
                    opacity: 0
                    transform: scale3d(0.7, 1, 1)
                    transition: transform 0.4s, opacity 0.4s
                    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1)
                    background: var(--backgroundBlue)
            .nav-link.active,
            .nav-link:hover
                color: #ffffff
                border-color: transparent
                background-color: transparent
            .nav-link,
            .nav-link::before
                transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1)

            .nav-link.active::before,
            .nav-link:hover::before
                opacity: 1
                transform: translate3d(0, 0, 0) scale3d(1, 1, 1)




// ≤992px up ↑ min lg
@media (min-width: 992px)
    .page-about
        .card-creative
            .thumbnail-inner
                //margin-top: -163px
                //margin-bottom: 60px
                padding: 30px 10px
                position: absolute
                left: 0
                right: 0
                top: 79px
                opacity: 0
                visibility: hidden
                transition: .33s all ease-out
        .card-creative__inner
            transform: translate3d(0, -36px, 0)
            transition: 0.33s cubic-bezier(0.4, 0, 0.2, 1)
        .section-lg
            padding: 100px 0


// ≤1200px up ↑ min xl
@media (min-width: 1200px)
    .page-about
        * + .card-creative__divider
                margin-top: 20px
        .card-creative__title
            font-size: 24px
        .box-counter__main
            font-size: 60px
            &.small
                font-size: 36px
            * + .box-counter__divider
                    margin-top: 15px
        * + .box-counter__title
                margin-top: 18px





// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
    .page-about
        .section-bg-custom
            padding-bottom: 28.9%