.sectionBlockOne
    .blurb-boxed-2
        height: 100%
        position: relative
        z-index: 2
        padding: 25px
        color: #ffffff
        background: rgba(0, 0, 0, 0)
        &::before
            content: ""
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            z-index: -1
            background: #2840b6
        &::after
            content: ""
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            z-index: 1
        .exeption
            position: relative
            z-index: 2
            opacity: 0.6
        * + .exeption
            margin-top: 5px
            margin-bottom: 0
        * + .title
            margin-top: 11px
            font-size: 18px
            line-height: 1.5
            position: relative
            z-index: 2
        &.blurb-boxed-dark
            &::after
                background: rgba(0, 0, 0, 0.12)
        &.blurb-boxed-darker
            &::after
                background: rgba(0, 0, 0, 0.23)
        &.blurb-boxed-darkest
            &::after
                background: rgba(0, 0, 0, 0.3)




/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)

// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .sectionBlockOne
        .blurb-boxed-2
            padding: 64px 84px

// ≤1200px up ↑ min xl
@media (min-width: 1200px)
    .sectionBlockOne
        h6 + p,
        .heading-6 + p
            margin-top: 21px

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
    .sectionBlockOne
        .blurb-boxed-2
            * + .exeption
                margin-top: 10px
            * + .title
                margin-top: 22px
                font-size: 20px
                line-height: 1.6

        h6 + p,
        .heading-6 + p
            margin-top: 25px
