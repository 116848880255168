.sectionBlockFour
    position: relative
    .mainProjects
        .swiper
            margin-left: -3px
            margin-right: -3px
            padding-left: 3px
            padding-right: 3px
        .swiper-slide
            height: auto
    .inner_with-image
        width: 100%
        height: 100%
        position: relative
        background-color: #fff
        transition: padding-bottom 250ms ease
        &:hover
            padding-bottom: 12%
            .tile__img-box
                padding-top: 48%
        .tile__main-link
            width: 100%
            height: 100%
            position: absolute
            z-index: 5
            top: 0
            left: 0
            display: block
            overflow: hidden
            box-shadow: 0 3px 5px 2px rgba(34, 60, 80, 0.2)
            //border: 1px solid transparent
            transition: box-shadow 250ms ease,transform 250ms ease
            text-decoration: none
            cursor: pointer
            &:hover
                //border-color: var(--backgroundBlue)
                &:after
                    height: 80px
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMDAgMjUiPjxnPjxnPjxwYXRoIGZpbGw9IiMyODQwYjYiIGQ9Ik0wIDExaDkzLjg3OWwtOC40NTEtOC40NUw4Ny41NDkuNDI2bDEyLjAyIDEyLjAyMS0uMDUuMDUuMDUuMDVMODcuNTUgMjQuNTdsLTIuMTIxLTIuMTJMOTMuODc1IDE0SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)
                    animation: fadeInUp 250ms ease-out
                + .tile__img-box + .treding-new .h3
                    color: #000
            &:after
                width: 100%
                height: 0
                content: ""
                position: absolute
                bottom: 0
                left: 0
                margin: 0 40px
                background-repeat: no-repeat
                background-position: left center

        .tile__img-box
            position: relative
            height: 0
            display: block
            padding-top: 60%
            overflow: hidden
            transition: padding-top 250ms ease

        .tile__img-box-inner
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            display: block

    .treding-new
        padding: 25px 20px
        .h3
            font-size: 20px
            line-height: 24px
            font-weight: 700
            text-transform: uppercase
            color: var(--colorBlue)
            margin-bottom: 22px
            -webkit-transition: all .4s ease-in-out
            -moz-transition: all .4s ease-in-out
            -ms-transition: all .4s ease-in-out
            -o-transition: all .4s ease-in-out
            transition: all .4s ease-in-out

        p
            font-size: 14px
            line-height: 24px
            font-weight: 300
            margin-bottom: 0

        span
            font-size: 14px
            font-weight: 300

            a
                font-weight: 400
                padding-left: 10px

    .title_news
        margin-bottom: 25px

        .h3
            font-size: 35px
            font-weight: 700
            color: #000
            text-transform: uppercase

        a
            font-size: 20px
            font-weight: 500

            &:hover
                color: #000


/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)
    .sectionBlockFour
        &:before
            width: 388px
            height: 375px
        .inner_with-image
            padding-bottom: 12%
            .tile__img-box
                padding-top: 48%
            .tile__main-link
                //border-color: var(--backgroundBlue)
                &:after
                    height: 80px
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMDAgMjUiPjxnPjxnPjxwYXRoIGZpbGw9IiMyODQwYjYiIGQ9Ik0wIDExaDkzLjg3OWwtOC40NTEtOC40NUw4Ny41NDkuNDI2bDEyLjAyIDEyLjAyMS0uMDUuMDUuMDUuMDVMODcuNTUgMjQuNTdsLTIuMTIxLTIuMTJMOTMuODc1IDE0SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)
                    animation: fadeInUp 250ms ease-out

        .title_news
            .h3
                font-size: 20px
            a
                font-size: 16px
        .mainProjects
            .swiper-slide
                width: 340px
                height: auto
// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .sectionBlockFour
        .mainProjects
            .swiper-slide
                width: 300px
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .sectionBlockFour
        .mainProjects
            .swiper-slide
                width: 340px
// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)
    .sectionBlockFour
        .mainProjects
            .swiper-slide
                width: inherit
// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .sectionBlockFour
        &::before,
        &::after
            position: absolute
            content: url("img/section-element.png")
        &::before
            top: 50px
            left: 50px
        &::after
            right: 50px
            bottom: 120px
            transform: rotateZ(90deg)

// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)