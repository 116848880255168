.b-wrap.page-projects
    background-color: var(--bgGraywhite)
.catalog-project
    .inner_with-image
        width: 100%
        height: 100%
        position: relative
        background-color: #fff
        transition: padding-bottom 250ms ease

        &:hover
            padding-bottom: 12%

            .tile__img-box
                padding-top: 48%

        .tile__main-link
            width: 100%
            height: 100%
            position: absolute
            z-index: 5
            top: 0
            left: 0
            display: block
            overflow: hidden
            box-shadow: 0 3px 5px 2px rgba(34, 60, 80, 0.2)
            //border: 1px solid transparent
            transition: box-shadow 250ms ease,transform 250ms ease
            text-decoration: none
            cursor: pointer

            &:hover
                //border-color: var(--backgroundBlue)
                &:after
                    height: 80px
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAxMDAgMjUiPjxnPjxnPjxwYXRoIGZpbGw9IiMyODQwYjYiIGQ9Ik0wIDExaDkzLjg3OWwtOC40NTEtOC40NUw4Ny41NDkuNDI2bDEyLjAyIDEyLjAyMS0uMDUuMDUuMDUuMDVMODcuNTUgMjQuNTdsLTIuMTIxLTIuMTJMOTMuODc1IDE0SDB6Ii8+PC9nPjwvZz48L3N2Zz4=)
                    background-repeat: no-repeat
                    animation: fadeInUp 250ms ease-out

                + .tile__img-box + .treding-new .h3
                    color: #000

            &:after
                width: 50%
                height: 0
                content: ""
                position: absolute
                bottom: 0
                left: 0
                margin: 0 40px
                background-repeat: no-repeat
                background-position: left center

        .tile__img-box
            position: relative
            height: 0
            display: block
            padding-top: 60%
            overflow: hidden
            transition: padding-top 250ms ease

        .tile__img-box-inner
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            display: block

        .treding-new
            padding: 25px 20px
            .h3
                font-size: 20px
                line-height: 24px
                font-weight: 700
                text-transform: uppercase
                color: var(--colorBlue)
                margin-bottom: 22px
                -webkit-transition: all .4s ease-in-out
                -moz-transition: all .4s ease-in-out
                -ms-transition: all .4s ease-in-out
                -o-transition: all .4s ease-in-out
                transition: all .4s ease-in-out

            p
                font-size: 14px
                line-height: 24px
                font-weight: 300
                margin-bottom: 0

            span
                font-size: 14px
                font-weight: 300

                a
                    font-weight: 400
                    padding-left: 10px

        .title_news
            margin-bottom: 25px

            .h3
                font-size: 35px
                font-weight: 700
                color: #000
                text-transform: uppercase

            a
                font-size: 20px
                font-weight: 500

                &:hover
                    color: #000