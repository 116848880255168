.headerItem
    padding-top: 7px
    padding-bottom: 7px
.mainMenu
    li
        a
            color: #ffffff
            text-decoration: none
            text-transform: uppercase
            font-size: 16px
            font-weight: 600
.headerTop .pb
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 4

.headerTop.stick.sticky
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 9999
    margin: 0
    padding: 0

.headerTop.stick.sticky .headerItem
    background: rgba(40, 64, 182, 0.8)


//.scrollDown
    width: 100%
    position: fixed
    top: 0
    left: 0
    z-index: 201
    transition: -webkit-transform .2s ease
    -webkit-transition: -webkit-transform .2s ease
    -o-transition: transform .2s ease
    transition: transform .2s ease
    transition: transform .2s ease, -webkit-transform .2s ease
    -webkit-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)
    background: var(--backgroundBlue)

//.scrollUp
    -webkit-transform: translateY(-90px)
        -ms-transform: translateY(-90px)
            transform: translateY(-90px)



/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)

// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 575.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)


/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .headerItem
        padding-top: 15px
        padding-bottom: 15px
// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)