.b-wrap.page-contacts
    background-color: var(--bgGraywhite)
.pageContacts
    .itemVal
        position: relative
    input.error
        position: relative
        border-color: red !important
    select[name="category"]
        position: relative
    .error
        position: absolute
        left: 0
        bottom: 0
    .title-sm
        .h2
            span
                position: relative
                z-index: 1
                padding-left: 25px
                padding-right: 25px
                background-color: #fff
    .wrapForm
        padding: 45px 15px
        background: #FFF
    .dropzone
        border: 2px dashed #3498db !important
        border-radius: 5px
        transition: .2s
        &.dz-clickable
            span
                color: #1560bd
    .contact-form-sec
        .h3
            font-weight: 600
            text-transform: capitalize
            font-size: 30px
            margin-bottom: 40px
            padding-left: 15px
            color: #bee260
        input
            height: 42px
            padding: 0 15px
            width: 100%
            color: #999
            font-size: 14px
            font-weight: 300
            margin-bottom: 35px

            &.error
                border-color: var(--accent)
        .form-select
            height: 42px
            font-size: 14px
            color: #999
            font-weight: 300
            margin-bottom: 35px
        textarea
            width: 100%
            color: #999
            font-size: 14px
            font-weight: 300
            margin-bottom: 15px
            height: 168px
            padding: 15px
            resize: none
            &.error
                border-color: var(--accent)
        .text_info
            color: #888888
        .button
            color: #000
            font-size: 14px
            font-weight: 600
            text-transform: uppercase
            //background-color: var(--accent)
            padding: 14px 35px
            //border: 0
            margin-top: 35px
            cursor: pointer

    .form-sec
        padding-bottom: 170px
#map
    -webkit-filter: grayscale(1)
    filter: grayscale(1)
    -webkit-transition: -webkit-filter 1s ease-out
    transition: -webkit-filter 1s ease-out
    -o-transition: filter 1s ease-out
    transition: filter 1s ease-out
    transition: filter 1s ease-out, -webkit-filter 1s ease-out
    &:hover
        -webkit-filter: grayscale(0)
        filter: grayscale(0)

.bg_fonFidback
    height: 100%
    position: relative
    &:before
        content: ""
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background: url("../img/bg-contacts.png")
        background-repeat: no-repeat
        background-size: cover
        background-position: center center


.alert-success.show
    display: block !important

/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .pageContacts
        .title-sm
            .h2
                span
                    width: 80%
        .successForm
            display: none
        .dropzoneForm.hide
            display: none
        .contact-form-sec
            .alert-success
                font-size: 25px !important
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)
    .pageContacts
        .wrapForm
            padding: 45px 50px
        .successForm
            position: relative
            z-index: -1
            opacity: 0
        .dropzoneForm.hide
            position: relative
            z-index: -1
            opacity: 0
// ≤1200px up ↑ min xl
@media (min-width: 1200px)
    .pageContacts
        .title-sm
            margin-bottom: 50px
        .contact-form-sec
            padding-right: 30px
// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
