.b-footer
    width: 100%
    position: fixed
    bottom: 0
    left: 0
    z-index: -1
    border: 0px solid #fff
    border-top: 0
    -webkit-transition: border .3s ease,padding .3s ease
    -moz-transition: border .3s ease,padding .3s ease
    -ms-transition: border .3s ease,padding .3s ease
    -o-transition: border .3s ease,padding .3s ease
    transition: border .3s ease,padding .3s ease
    .footer-data
        padding: 70px 0 70px 0
        background: var(--backgroundBlue)
        .h5
            padding-bottom: 5px
            margin-bottom: 20px
            border-bottom: 1px solid rgba(255,255,255,.3)
            color: #ffffff
        p
            color: var(--colorGraywhite)
            font-size: 15px
    .footer__project
        color: #ffffff
        padding-bottom: 20px
        padding-top: 20px
        background: #3b506e
    .menu-f
        a
            text-decoration: none
            color: #ffffff
            font-size: 15px
            transition: .33s all ease-out
            color: var(--colorGraywhite)
            &:hover
                color: #ffffff
                text-decoration: none
    .list-info-f
        a
            color: #ffffff
            display: flex
            font-size: 15px
            text-decoration: none
        .f-text
            transition: .33s all ease-out
            color: var(--colorGraywhite)
            &:hover
                color: #ffffff
                text-decoration: none




/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)
    .b-footer
        .f-title__after
            margin: 0
            cursor: pointer
            position: relative
            &:after
                position: absolute
                top: 3px
                right: 32px
                transform: rotate(45deg)
                transform-origin: right
                margin-top: 4px
                width: 8px
                height: 8px
                border-radius: 0px
                border-right: 2px solid #fff
                border-bottom: 2px solid #fff
                content: ""
        .js-checked.f-title__after::after
            right: 39px
            transform: rotate(-135deg)
// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .b-footer
        .footer-data
            padding-top: 35px
            padding-bottom: 35px

// Малые девайсы («ландшафтные», >= 575px)
@media (min-width: 480px) and (max-width: 767.98px)
    .b-wrap
        margin-bottom: 0 !important
        .b-footer
            height: inherit !important
            position: relative !important
            z-index: inherit !important

// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 575.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)

// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)
