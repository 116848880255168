.swiper-reviews
    .review-list
        .review
            height: 100%
            padding: 30px 55px 30px 55px
            background: #fff
        .name, .font-body
            font-size: 16px
        .subtitle
            font-size: 14px
            line-height: 20px
            color: #7a8086
        .swiper-slide
            height: auto

/*==========  Desktop First  ==========*/
// (≥1440px) (≥1500px) down ↓ max xxxl 1
@media (max-width: 1499.98px)

// (≥1340px) (≥1440px) down ↓ max xxl 1
@media (max-width: 1439.98px)

// (≥1200px) (≥1340px) down ↓ max lg 1
@media (max-width: 1339.98px)

// (≥992px) (≥1200px) down ↓ max lg 2
@media (max-width: 1199.98px)

// (≥768px) (≥992px) down ↓ max md 3
@media (max-width: 991.98px)

// (≥576px) (≥768px) down ↓ max sm 4
@media (max-width: 767.98px)
    .swiper-reviews
        .review-list
            .review
                padding: 30px 15px 30px 15px
// (≥480px) (auto) (≥540px) down ↓ max xd 5
@media (max-width: 559.98px)

// (≥360px) (auto) (≥480px) down ↓ max zx 6
@media (max-width: 479.98px)

// (auto) (<320px) down ↓ max sx 7
@media (max-width: 359.98px)

/*==========  Mobile First  ==========*/

// ≤320px up ↑ min sx
@media (min-width: 320px)

// ≤360px up ↑ min zx
@media (min-width: 360px)

// ≤480px up ↑ min xd
@media (min-width: 480px)

// ≤540px up ↑ min sm
@media (min-width: 560px)

// ≤768px up ↑ min md
@media (min-width: 768px)

// ≤992px up ↑ min lg
@media (min-width: 992px)

// ≤1200px up ↑ min xl
@media (min-width: 1200px)

// ≤1340px up ↑ min xxl
@media (min-width: 1340px)

// ≤1400px up ↑ min xxxl
@media (min-width: 1400px)

// ≤1565px up ↑
@media (min-width: 1565px)